import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-submit"
export default class extends Controller {
  static targets = [ "form"]
  connect() {
    this.debounceTimeout = null;
  }

  inputChanged(event) {
    const targetType = event.target.type;
    
    // For text and color inputs, debounce the form submission to avoid triggering after every keystroke
    if (targetType === "text" || targetType === "textarea" || targetType === "color") {
      this.debounce(() => this.submitForm(), 500); // 500ms delay
    } else {
      this.submitForm(); // Immediately submit for other input types
    }
  }

  submitForm() {
    // Submit the form
    this.formTarget.requestSubmit();
  }

  debounce(callback, delay) {
    clearTimeout(this.debounceTimeout); // Clear any previous timeout
    this.debounceTimeout = setTimeout(callback, delay); // Set a new timeout
  }

}
